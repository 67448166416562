import dashboard from "./dashboard.json"
import clientManagement from "./client-management.json"
import billingManagement from "./billing-management"
import packagesManagement from "./packages-management.json"
import userManagement from "./user-management.json"
import domainManagement from "./domain-management.json"
import approval from "./approval.json"
import notification from "./notification-management.json"
import ticket from "./support-ticket.json"
import setting from "./setting.json"
import error from "./error.json"
import menu from "./menu.json"
import message from "./message.json"
import required from "./required.json"
import profile from "./user-profile.json"
import authentication from "./authentication.json"
import role from "./role.json"
import payment from "./payment.json"
import activityLog from "./activity-log.json"
import permissons from "./permissons.json"

const translate = {
  ID: "ID",
  Email: "電子メール",
  Password: "パスワード",
  "Client Name": "クライアント名",
  Packages: "Packages",
  "Created at": "広告を作成する",
  "View details": "詳細を見る",
  "Forgot password Forgot": "パスワードをお忘れですか",
  "Remember login": "ログインを記憶する",
  "Log in your account": "アカウントにログインします",
  Logout: "ログアウト",
  "Choose an option": "オプションを選択",
  "Edit image": "画像を編集",
  "File size exceeds the allowed limit 2 MB": "ファイルサイズが許可された2MBを超えています",
  "This file doesn't support.": "このファイルはサポートされていません",
  "Powered by": "パワード・バイ",
  Verify: "確認",
  Enable: "有効化",
  Disable: "無効化",
  "Notification Detail": "通知の詳細",
  "attachment files": "添付ファイル",
  s: "秒",
  m: "分",
  h: "時",
  d: "日",
  ago: "前",
  Done: "完了",
  "Picture here": "画像はこちら",
  "Supported formats: png, jpg": "サポートされている形式: png、jpg",
  "Custom domain setting": "カスタムドメイン設定",
  "This page is comming soon": "このページは近日公開予定です",
  "This page is not unknown or does not exsit": "このページは不明ではないか、存在しません",
  "Sorry about that, but the page you looking for is not available":
    "申し訳ありませんが、お探しのページは見つかりませんでした",
  "Service unavailable": "サービスが利用できません",
  "This server is temporary busy, try again later":
    "このサーバーは一時的に混雑しています。後でもう一度お試しください",
  Picture: "写真",
  ...dashboard,
  ...clientManagement,
  ...billingManagement,
  ...packagesManagement,
  ...userManagement,
  ...domainManagement,
  ...approval,
  ...notification,
  ...ticket,
  ...setting,
  ...error,
  ...menu,
  ...message,
  ...required,
  ...profile,
  ...authentication,
  ...role,
  ...payment,
  ...activityLog,
  ...permissons
}

export default translate
